import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import { Cancel, Check } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import { v4 } from "uuid";
import CampoDoc from "../../../../components/CampoDoc";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../../config/axios";

const itemVinculacionDefault = {
  fecId: 0,
  fecPerfil: "GUARDIA",
  fecIdPersona: 1,
  fecVinculacion: "",
  fecRetiro: "",
  fecReg: "",
  fecRegUpdate: "",
  fecUser: "",
  fecUserUpdate: "",
};
const RowAgregarPrenda = ({
  estadoRequest,
  id,
  setvinculacionesData,
  setagregar,
}) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const [itemHijo, setitemHijo] = useState(itemVinculacionDefault);
  const [cargando, setcargando] = useState(false);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.fecVinculacion.trim() === "") {
      return true;
    }
  };
  const { usuario } = useContext(AuthContext);
  // ELIMINAR EL REGISTRO
  const funcSubmit = async () => {
    try {
      setcargando(true);
      console.log({
        ...itemHijo,
        fecIdPersona: id,
        fecPerfil:
          estadoRequest === "GUARDIAS"
            ? "GUARDIA"
            : estadoRequest === "SUPERVISORES"
            ? "SUPERVISOR"
            : "ADMINISTRACION",
      });

      const res = await clienteAxios.post("/fechasvinculacion", {
        ...itemHijo,
        fecIdPersona: id,
        fecPerfil:
          estadoRequest === "GUARDIAS"
            ? "GUARDIA"
            : estadoRequest === "SUPERVISORES"
            ? "SUPERVISOR"
            : "ADMINISTRACION",
      });
      console.log({ res });
      setvinculacionesData((prev) => [res.data.data, ...prev]);
      setitemHijo(itemVinculacionDefault);
      setagregar(false);
      setcargando(false);
    } catch (error) {
      console.log({ error });

      setcargando(false);
    }
  };
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ButtonsAction
        botones={[
          {
            tooltip: "Cancelar",
            texto: "",
            funcion: () => {
              setagregar(false);
              setitemHijo(itemVinculacionDefault);
              // setItemEdit(itemHijo);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
          },
          {
            tooltip: "Guardar",
            texto: "",
            funcion: () => {
              funcSubmit();
            },
            disabled: botonDisabled() || cargando,
            Icon: Check,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
            submit: true,
          },
        ]}
      />
      <>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            type="date"
            value={itemHijo.fecVinculacion}
            name="fecVinculacion"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {/* <TextField
            type="date"
            value={itemHijo.fecRetiro}
            name="fecRetiro"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />{" "} */} 
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          -
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          -
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {usuario.usuario}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          -
        </TableCell>
      </>
    </TableRow>
  );
};

export default withRouter(memo(RowAgregarPrenda));
