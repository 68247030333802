import React, { useState, useContext, useEffect, memo, createRef } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
// import { validarEmail } from "../../../../config/validaciones";

import RowAgregarArma from "./RowsArma/RowAgregarArma";
import RowArma from "./RowsArma/RowArma";
import RowDatoOperativo from "./RowsDatosOperativos/RowDatoOperativo";
import RowAgreagarDatoOperativo from "./RowsDatosOperativos/RowAgreagarDatoOperativo";
import { Download, Search } from "@mui/icons-material";
import DialogMap from "../../../components/DialogMap";
import {
  validarDocumento,
  validarEmail,
} from "../../../../config/validaciones";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../config/const";
import Dropzone from "../../../components/Dropzone";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Input,
  Chip,
  useTheme,
  Avatar,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableHead,
  TableBody,
  FormControlLabel,
  Switch,
  Tooltip,
  Autocomplete,
  LinearProgress,
} from "@mui/material";
import {
  AddCircle,
  Cancel,
  Delete,
  CloudUpload,
  Map,
} from "@mui/icons-material";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { useModalStyle } from "../../../styles/styleModal";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  const [clienteProveedor] = useState("CLIENTES");
  const [ciudadesData, setciudadesData] = useState(null);
  const [documentosArray, setdocumentosArray] = useState(null);
  const [documentosObject, setdocumentosObject] = useState(null);
  const [objetoReferencias, setobjetoReferencias] = useState(null);
  const [clientesData, setclientesData] = useState([]);

  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdeProforma,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [openMap, setopenMap] = useState(false);
  //datpos operativos

  const [agregandoDatoOperativo, setagregandoDatoOperativo] = useState(false);
  const [pageDatoOperativo, setPageDatoOperativo] = React.useState(0);
  const [
    rowsPerPageDatoOperativo,
    setRowsPerPageDatoOperativo,
  ] = React.useState(10);
  const handleChangePageDatoOperativo = async (event, newPage) => {
    setPageDatoOperativo(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPageDatoOperativo = (event) => {
    setRowsPerPageDatoOperativo(+event.target.value);
    setPageDatoOperativo(0);
  };

  const [agregandoBodega, setagregandoBodega] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [cargando, setcargando] = useState(false);
  const [archivoFoto, setArchivoFoto] = useState("");

  const [archivoLogo, setArchivoLogo] = useState("");
  const [archivoMostradoLogo, setarchivoMostradoLogo] = useState(null);
  //ADJUDICION

  const focusInputDocumento = (itemPropiedad) => {
    objetoReferencias[itemPropiedad.propiedad].current.click();
  };
  const onChangeDocumento = async (e, itempropiedad) => {
    if (!e.target.files[0]) {
      return;
    }
    if (itempropiedad.extension === "PDF") {
      if (
        !["pdf"].some((item) => item === e.target.files[0].type.split("/")[1])
      ) {
        return mostrarAlerta("El archivo debe ser PDF", "error");
      }
    }
    if (itempropiedad.extension === "IMAGEN") {
      if (
        !["png", "jpg", "jpeg"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
      }
    }
    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [];
      if (documentos[itempropiedad.propiedad] === "") {
        await formDataLogos.append("archivo", e.target.files[0]);
      } else {
        arrayEliminar.push(documentos[itempropiedad.propiedad]);
        await formDataLogos.append("archivo", e.target.files[0]);
      }

      formDataLogos.append("carpeta", "documentos");

      const resDocumentos = await clienteAxios.post(
        "/subir_eliminar_archivo/subir",
        formDataLogos
      ); 

       await clienteAxios.post(
        "/subir_eliminar_archivo/eliminar",
        { urls: arrayEliminar.filter((itemE) => itemE !== "") }
      ); 

      setitem({
        ...item,
        documentos: {
          ...item.documentos,
          [itempropiedad.propiedad]: resDocumentos.data.url,
        },
      });

      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: e.target.files[0],
      });
      setcargando(false);
    } catch (error) {
      setcargando(false);
      setdocumentosObject({
        ...documentosObject,
        [itempropiedad.propiedad]: documentosObject[itempropiedad.propiedad],
      });

      return mostrarAlerta("Hubo un error", "error");
    }
  };

  //LOGO
  const onChangeArchivoLogo = (e) => {
    if (!e.target.files[0]) {
      setarchivoMostradoLogo(null);
      return setArchivoLogo("");
    }

    if (
      !["png", "jpg", "jpeg"].some(
        (item) => item === e.target.files[0].type.split("/")[1]
      )
    ) {
      setarchivoMostradoLogo(null);
      setArchivoLogo("");
      return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
    }

    setArchivoLogo(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      e.preventDefault();
      setarchivoMostradoLogo(e.target.result);
    };
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        height: "auto",
      },
    },
  };
  function getStylesEspecialidades(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }

  const theme = useTheme();

  useEffect(() => {
    //  https://parzibyte.me/blog

    if (!ciudadesData) {
      try {
        const getData = async () => {
          const resCiudades = await clienteAxios.get(`/ciudades/filtro/0`);

          setciudadesData(resCiudades.data.data);
        };
        getData();
      } catch (error) {}
    } // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const {
    documentos,
    cliTiempoInicioContrato,
    cliTiempoFinalContrato,
    cliDocTipo,
    cliDocNumero,
    cliRazonSocial,
    cliNombreComercial,
    // cliNombres,
    cliDireccion,
    cliInstitucion,
    cliUbicacion,
    cliTelefono,
    // cliCelular,
    perEmail,
    cliAdministradorContrato,
    cliArmasAsignadas,
    cliDatosOperativos,
    cliEquiposAsignados,
    cliOtrosEquipos,
    // perProvincia,
    // perCanton,
    perFoto,
    cliCiudad,
    perLogo,
    // cliObservacion,
    perEstado,
    perGuardias,
    perFotografiasInstalaciones,
    perFijo,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    cliCiudad: false,
    cliTiempoInicioContrato: false,
    cliTiempoFinalContrato: false,
    cliDocTipo: false,
    cliDocNumero: false,
    cliRazonSocial: false,
    cliNombreComercial: false,
    cliNombres: false,
    cliDireccion: false,
    cliInstitucion: false,
    cliUbicacion: false,
    cliTelefono: false,
    cliCelular: false,
    perEmail: false,
    cliAdministradorContrato: false,
    cliArmasAsignadas: false,
    cliDatosOperativos: false,
    cliEquiposAsignados: false,
    cliOtrosEquipos: false,
    perProvincia: false,
    perCanton: false,
    perFoto: false,
    cliDocuAdjudicacion: false,
    cliDocuContrato: false,
    cliDocuRuc: false,
    cliFotosInstalacion: false,
    perLogo: false,
    cliObservacion: false,
  });

  useEffect(() => {
    const getData = async () => {
      const res = await clienteAxios.get("/documentos/filtro/0?perfil=CLIENTE");
      const itemRes = res.data.data[0];

      // itemRes.docDocumentos;
      setdocumentosArray(itemRes.docDocumentos);

      if (editar) {
        const documentos = {};
        itemRes.docDocumentos.forEach((itemDocumento) => {
          if (!ObjectoActivo.documentos[itemDocumento.propiedad]) {
            documentos[itemDocumento.propiedad] = "";
            documentos[itemDocumento.propiedad + "expira"] = "";
          }
        });

        //DOCUMENTOS OBJECT
        const documentosObject = {};
        itemRes.docDocumentos.forEach((itemDocumento) => {
          documentosObject[itemDocumento.propiedad] = "";
        });

        //DOCUMENTOS OBJECT
        const objetoReferencias = {};
        itemRes.docDocumentos.forEach((itemDocumento) => {
          objetoReferencias[itemDocumento.propiedad] = createRef();
        });

        setobjetoReferencias(objetoReferencias);
        setdocumentosObject(documentosObject);
        setdocSearch(ObjectoActivo.cliDocNumero);
        setitem({
          ...ObjectoActivo,
          documentos: { ...ObjectoActivo.documentos, ...documentos },
        });
      } else {
        const documentos = {};
        itemRes.docDocumentos.forEach((itemDocumento) => {
          documentos[itemDocumento.propiedad] = "";
          documentos[itemDocumento.propiedad + "expira"] = "";
        });

        //DOCUMENTOS OBJECT
        const documentosObject = {};
        itemRes.docDocumentos.forEach((itemDocumento) => {
          documentosObject[itemDocumento.propiedad] = "";
        });

        //DOCUMENTOS OBJECT
        const objetoReferencias = {};
        itemRes.docDocumentos.forEach((itemDocumento) => {
          objetoReferencias[itemDocumento.propiedad] = createRef();
        });

        setobjetoReferencias(objetoReferencias);
        setdocumentosObject(documentosObject);
        setitem({
          ...ObjectoActivo,
          documentos,
          // perDocTipo: clienteProveedor === "USUARIOS" ? "PASAPORTE" : "RUC",
          // perOtros: ObjectoActivo.perOtros ? ObjectoActivo.perOtros : [],
        });
      }
    };
    getData();

    // eslint-disable-next-line
  }, [ObjectoActivo]);
  const [docSearch, setdocSearch] = useState("");
  const [cargandoDoc, setcargandoDoc] = useState(false);
  const defaultActive = () => {
    setdocSearch("");
    setmodificoImagenes(false);
    changeObjetoActivoState({
      //   cliId: 1,
      perGuardias: "1",
      documentos: {},
      cliTiempoInicioContrato: "",
      cliTiempoFinalContrato: "",
      perPerfil: ["CLIENTE"],
      cliDocTipo: "RUC",
      cliDocNumero: "",
      cliRazonSocial: "",
      cliNombreComercial: "",
      cliNombres: "",
      cliDireccion: "",
      cliInstitucion: "PUBLICA",
      cliUbicacion: {
        longitud: -79.178298,
        latitud: -0.254916,
      },
      cliTelefono: [],
      cliCelular: "",
      perEmail: [],
      cliAdministradorContrato: "",
      cliCiudad: "",
      cliArmasAsignadas: [],
      cliDatosOperativos: [],
      cliEquiposAsignados: [],
      cliOtrosEquipos: "",
      cliEmpresa: [],
      perPais: "ECUADOR",
      perProvincia: "SANTO DOMINGO DE LOS TSÁCHILAS",
      perCanton: "SANTO DOMINGO",
      perEstado: "ACTIVO",
      perFoto: "",
      perFotografiasInstalaciones: [],
      perLogo: "",
      cliUser: "",
      cliObservacion: "",
      cliFecReg: "",
      Todos: "",
      perFijo: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      cliCiudad: false,
      cliTiempoInicioContrato: false,
      cliTiempoFinalContrato: false,
      cliDocTipo: false,
      cliDocNumero: false,
      cliRazonSocial: false,
      cliNombreComercial: false,
      cliNombres: false,
      cliDireccion: false,
      cliInstitucion: false,
      cliUbicacion: false,
      cliTelefono: false,
      cliCelular: false,
      perEmail: false,
      cliAdministradorContrato: false,
      cliArmasAsignadas: false,
      cliDatosOperativos: false,
      cliEquiposAsignados: false,
      cliOtrosEquipos: false,
      perProvincia: false,
      perCanton: false,
      perFoto: false,
      cliDocuAdjudicacion: false,
      cliDocuContrato: false,
      cliDocuRuc: false,
      cliFotosInstalacion: false,
      perLogo: false,
      cliObservacion: false,
    });
    setseccionActive({
      DatosGenerales: true,
      DatosOperativos: false,
      DatosLogistica: false,
      Adjuntos: false,
    });
    setcargando(false);
    setArchivoFoto("");
    setArchivoLogo("");
    setarchivoMostradoLogo(null);
    setobjetoReferencias(null);
    setdocumentosObject(null);
    // setdocumentoAdjudicacion("");
    setimagenes([]);
    setmodificoImagenes(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    DatosOperativos: false,
    DatosLogistica: false,
    Adjuntos: false,
    Fotografias: false,
  });
  const {
    DatosGenerales,
    DatosOperativos,
    DatosLogistica,
    Adjuntos,
    Fotografias,
  } = seccionActive;
  // eslint-disable-next-line
  const validacion = () => {
    if (
      cliCiudad.trim() === "" ||
      cliDocNumero.trim() === "" ||
      cliRazonSocial.trim() === "" ||
      cliNombreComercial.trim() === "" ||
      cliDireccion.trim() === "" ||
      cliTelefono.length === 0 ||
      perEmail.some((email) => {
        return validarEmail(email);
      }) ||
      perEmail.length === 0
      //   //   // perEmail
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      const newError = {
        cliCiudad: cliCiudad.trim() === "" ? true : false,
        cliDocNumero: cliDocNumero.trim() === "" ? true : false,
        cliRazonSocial: cliRazonSocial.trim() === "" ? true : false,
        cliNombreComercial: cliNombreComercial.trim() === "" ? true : false,
        cliDireccion: cliDireccion.trim() === "" ? true : false,
        cliTelefono: cliTelefono.length === 0 ? true : false,
        perEmail:
          perEmail.some((email) => {
            return validarEmail(email);
          }) || perEmail.length === 0
            ? true
            : false,
      };

      seterror(newError);
      return true;
    }
  };
  const handleCrear = async () => {
    try {
      const obligatorios = documentosArray.filter(
        (documentoItem) => documentoItem.obligatorio === "SI"
      );
      obligatorios.map((obligatorioItem) => {
        if (documentos[obligatorioItem.propiedad] === "") {
          return {
            ...obligatorioItem,
            error: true,
          };
        } else {
          return {
            ...obligatorioItem,
            error: false,
          };
        }
      });

      const existeSinLlenar = obligatorios.some((obligatorioItem) => {
        return documentos[obligatorioItem.propiedad] === "";
      });

      if (existeSinLlenar && !desdeProforma) {
        const llenos = documentosArray.map((obligatorioItem) => {
          if (
            documentos[obligatorioItem.propiedad] === "" &&
            obligatorioItem.obligatorio === "SI"
          ) {
            return {
              ...obligatorioItem,
              error: true,
            };
          } else {
            return {
              ...obligatorioItem,
              error: false,
            };
          }
        });

        setcargando(false);
        setdocumentosArray(llenos);
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      if (validacion()) {
        return;
      }
      setcargando(true);
      const formDataLogosFoto = new FormData();
      let arrayEliminarFoto = item.perFoto ? [perFoto] : [];

      await formDataLogosFoto.append("archivo", archivoFoto);
      formDataLogosFoto.append("carpeta", "documentos");

      const resDocumentosFoto = archivoFoto
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosFoto
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarFoto.filter((itemE) => itemE !== ""),
      });

      const formDataLogosLogo = new FormData();
      let arrayEliminarLogo = item.perLogo ? [perLogo] : [];

      await formDataLogosLogo.append("archivo", archivoLogo);
      formDataLogosLogo.append("carpeta", "documentos");

      const resDocumentosLogo = archivoLogo
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosLogo
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarLogo.filter((itemE) => itemE !== ""),
      }); 

      const resperFotografiasInstalaciones = await subirImagenes(
        imagenes,
        perFotografiasInstalaciones
      );
      if (item.cliId) {
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            //   //documentos
            ...item,
            perFotografiasInstalaciones: resperFotografiasInstalaciones,
            perEmail: perEmail.map((email) => replaceCaracteres(email)),
            perFoto: resDocumentosFoto?.data?.url
              ? resDocumentosFoto.data.url
              : perFoto,
            perLogo: resDocumentosLogo?.data?.url
              ? resDocumentosLogo.data.url
              : perLogo,
            // documentos: { ...documentos, ...documentosUrls },
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            cliUser: usuario.usuario,
            cliEmpresa: [usuario.rucempresa],
            perPerfil: [...new Set([...item.perPerfil, "CLIENTE"])],
            tabla: "cliente",
          })
        );
      } else {
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            //   //documentos
            ...item,
            perFotografiasInstalaciones: resperFotografiasInstalaciones,
            perEmail: perEmail.map((email) => replaceCaracteres(email)),
            // documentos: { ...documentos, ...documentosUrls },
            perFoto: resDocumentosFoto?.data?.url
              ? resDocumentosFoto.data.url
              : perFoto,
            perLogo: resDocumentosLogo?.data?.url
              ? resDocumentosLogo.data.url
              : perLogo,
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            cliUser: usuario.usuario,
            cliEmpresa: [usuario.rucempresa],
            tabla: "cliente",
          })
        );
      }

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      await validarDocumento(cliDocNumero);

      // mostrarAlerta("Identificación válida", "success");
    } catch (error) {
      mostrarAlerta(error, "error");
      return seterror({
        ...error,
        cliDocNumero: true,
      });
    }
    if (validacion()) {
      return;
    }

    try {
      const obligatorios = documentosArray.filter(
        (documentoItem) => documentoItem.obligatorio === "SI"
      );
      obligatorios.map((obligatorioItem) => {
        if (documentos[obligatorioItem.propiedad] === "") {
          return {
            ...obligatorioItem,
            error: true,
          };
        } else {
          return {
            ...obligatorioItem,
            error: false,
          };
        }
      });
      const existeSinLlenar = obligatorios.some((obligatorioItem) => {
        return documentos[obligatorioItem.propiedad] === "";
      });
      if (existeSinLlenar && !desdeProforma) {
        const llenos = documentosArray.map((obligatorioItem) => {
          if (
            documentos[obligatorioItem.propiedad] === "" &&
            obligatorioItem.obligatorio === "SI"
          ) {
            return {
              ...obligatorioItem,
              error: true,
            };
          } else {
            return {
              ...obligatorioItem,
              error: false,
            };
          }
        });

        return setdocumentosArray(llenos);
      }
      setcargando(true);
      const formDataLogosFoto = new FormData();
      let arrayEliminarFoto = item.perFoto ? [perFoto] : [];

      await formDataLogosFoto.append("archivo", archivoFoto);
      formDataLogosFoto.append("carpeta", "documentos");

      const resDocumentosFoto = archivoFoto
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosFoto
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarFoto.filter((itemE) => itemE !== ""),
      });

      const formDataLogosLogo = new FormData();
      let arrayEliminarLogo = item.perLogo ? [perLogo] : [];

      await formDataLogosLogo.append("archivo", archivoLogo);
      formDataLogosLogo.append("carpeta", "documentos");

      const resDocumentosLogo = archivoLogo
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogosLogo
          )
        : null;

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminarLogo.filter((itemE) => itemE !== ""),
      }); 

      const resperFotografiasInstalaciones = await subirImagenes(
        imagenes,
        perFotografiasInstalaciones
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          //documentos
          ...item,
          perFotografiasInstalaciones: resperFotografiasInstalaciones,
          perEmail: perEmail.map((email) => replaceCaracteres(email)),
          // documentos: { ...documentos, ...documentosUrls },
          perFoto: resDocumentosFoto?.data?.url
            ? resDocumentosFoto.data.url
            : perFoto,
          perLogo: resDocumentosLogo?.data?.url
            ? resDocumentosLogo.data.url
            : perLogo,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          cliUser: usuario.usuario,
          cliEmpresa: [usuario.rucempresa],
          tabla: "cliente",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });

      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  if (!ciudadesData) {
    return null;
  }
  if (!documentosArray) {
    return null;
  }
  if (!documentosObject) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"}{" "}
                  {clienteProveedor === "GUARDIAS"
                    ? "Guardia"
                    : clienteProveedor === "SUPERVISORES"
                    ? "Supervisor"
                    : clienteProveedor === "ADMINISTRACIÓN"
                    ? "Administración"
                    : clienteProveedor === "CLIENTES"
                    ? "Cliente"
                    : ""}
                  .
                </Typography>
                <Typography variant="h5" color="initial" align="center">
                  {item.cliId}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <DialogMap
                open={openMap}
                setopen={setopenMap}
                setitem={setitem}
                item={item}
                editar={editar}
                propiedad={cliUbicacion}
                nombrePropiedad={"cliUbicacion"}
                solover={false}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={DatosGenerales ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button>
                  <Button
                    variant={DatosOperativos ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosOperativos: true })}
                  >
                    Datos Operativos
                  </Button>
                  {/* <Button
                  variant={DatosLogistica ? "contained" : null}
                  disableElevation
                  className={clases.buttonTab}
                   
                  color="secondary"
                  onClick={() => setseccionActive({ DatosLogistica: true })}
                >
                  Datos Logistica
                </Button> */}
                  <Button
                    variant={Adjuntos ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color="secondary"
                    onClick={() => {
                      if (
                        cliCiudad.trim() === "" ||
                        cliDocNumero.trim() === "" ||
                        cliRazonSocial.trim() === "" ||
                        cliNombreComercial.trim() === "" ||
                        cliDireccion.trim() === "" ||
                        cliTelefono.length === 0 ||
                        perEmail.some((email) => {
                          return validarEmail(email);
                        }) ||
                        perEmail.length === 0
                        //   //   // perEmail
                      ) {
                        mostrarAlerta(
                          "Faltan datos obligatorios por llenar",
                          "error"
                        );
                        const newError = {
                          cliCiudad: cliCiudad.trim() === "" ? true : false,
                          cliDocNumero:
                            cliDocNumero.trim() === "" ? true : false,
                          cliRazonSocial:
                            cliRazonSocial.trim() === "" ? true : false,
                          cliNombreComercial:
                            cliNombreComercial.trim() === "" ? true : false,
                          cliDireccion:
                            cliDireccion.trim() === "" ? true : false,
                          cliTelefono: cliTelefono.length === 0 ? true : false,
                          perEmail:
                            perEmail.some((email) => {
                              return validarEmail(email);
                            }) || perEmail.length === 0
                              ? true
                              : false,
                        };

                        return seterror(newError);
                      }
                      setseccionActive({ Adjuntos: true });
                    }}
                  >
                    Adjuntos
                  </Button>
                  <Button
                    variant={Fotografias ? "contained" : null}
                    disableElevation
                    className={clases.buttonTab}
                    color="secondary"
                    onClick={() => setseccionActive({ Fotografias: true })}
                  >
                    Fotografias
                  </Button>
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={
                        perEstado === "ACTIVO" || perEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          perEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box className={clases.container}>
                {DatosGenerales ? (
                  <>
                    {/* <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={clientesData.map((item) => {
                          return (
                            item.cliDocNumero +
                            "/+/" +
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocTipo
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={cliDocNumero}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            const findItem = clientesData.find(
                              (clienteData) =>
                                clienteData.cliDocNumero === options[0]
                            );

                            if (!findItem) {
                              mostrarAlerta("Hubo un error", "error");
                            }

                            if (findItem) {
                              setitem({
                                ...findItem,
                                documentos: {
                                  ...documentos,
                                  ...findItem.documentos,
                                },
                              });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            disabled={editar}
                            label={"N. Documento"}
                            fullWidth
                            error={error.cliDocNumero}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un documento",
                                  "error"
                                );
                              }
                              try {
                                setitem({
                                  ...item,
                                  cliDocNumero: e.target.value,
                                });

                                const res = await clienteAxios.get(
                                  `/clientes/agregarperfil/0?&search=${e.target.value}`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setclientesData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {!editar ? <> </> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl> */}
                    <Box
                      className={clases.formAllW}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <TextField
                        disabled={editar}
                        fullWidth
                        size="small"
                        required
                        label={"Número Doc."}
                        error={error.cliDocNumero}
                        value={docSearch}
                        onChange={async (e) => {
                          setdocSearch(e.target.value);
                          setitem({ ...item, cliDocNumero: e.target.value });
                        }}
                        helperText={`Presione "Enter" para buscar`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                aria-label=""
                                onClick={async (e) => {
                                  if (docSearch === "") {
                                    return mostrarAlerta(
                                      "Ingrese un documento",
                                      "error"
                                    );
                                  }
                                  try {
                                    setcargandoDoc(true);
                                    const res = await clienteAxios.get(
                                      `personas/searchByCedulaOfRuc/0?search=${docSearch}&perfil=${"CLIENTE"}`
                                    );
                                    if (res?.data) {
                                      changeObjetoActivoState({
                                        ...res.data,
                                        documentos: {
                                          ...documentos,
                                          ...res.data.documentos,
                                        },
                                      });
                                      setitem({
                                        ...res.data,
                                        documentos: {
                                          ...documentos,
                                          ...res.data.documentos,
                                        },
                                      });
                                    }
                                    setcargandoDoc(false);
                                  } catch (error) {
                                    setcargandoDoc(false);
                                    console.log({ error });
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                    setitem({
                                      ...ObjectoActivo,
                                      perDocNumero: docSearch,
                                    });
                                  }
                                }}
                              >
                                <Search color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={async (e) => {
                          if (e.key !== "Enter") {
                            return;
                          } 
                          if (e.target.value === "") {
                            return mostrarAlerta(
                              "Ingrese un documento",
                              "error"
                            );
                          }
                          try {
                            setcargandoDoc(true);
                            setitem({
                              ...item,
                              perDocNumero: e.target.value,
                            });
                            const res = await clienteAxios.get(
                              `personas/searchByCedulaOfRuc/0?search=${
                                e.target.value
                              }&perfil=${"CLIENTE"}`
                            );
                            if (res?.data) {
                              changeObjetoActivoState({
                                ...res.data,
                                documentos: {
                                  ...documentos,
                                  ...res.data.documentos,
                                },
                              });
                              setitem({
                                ...res.data,
                                documentos: {
                                  ...documentos,
                                  ...res.data.documentos,
                                },
                              });
                            }
                            setcargandoDoc(false);
                          } catch (error) {
                            console.log({ error });
                            setcargandoDoc(false);
                            mostrarAlerta(error?.response?.data?.msg, "error");
                            setitem({
                              ...ObjectoActivo,
                              perDocNumero: docSearch,
                            });
                          }
                        }}
                      />
                      {cargandoDoc && (
                        <LinearProgress
                          color="primary"
                          fourColor
                          variant="query"
                        />
                      )}
                    </Box>
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliDocTipo"
                      label="Tipo Documento"
                      value={cliDocTipo}
                      name="cliDocTipo"
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["RUC", "CEDULA", "PASAPORTE"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliInstitucion"
                      label="Institución"
                      value={cliInstitucion}
                      name="cliInstitucion"
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["PUBLICA", "PRIVADA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliRazonSocial"
                      label="Razón Social"
                      value={cliRazonSocial}
                      name="cliRazonSocial"
                      error={error.cliRazonSocial}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliNombreComercial"
                      label="Nombre Comercial"
                      value={cliNombreComercial}
                      name="cliNombreComercial"
                      error={error.cliNombreComercial}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliCiudad"
                      label="Ciudad"
                      value={cliCiudad}
                      name="cliCiudad"
                      error={error.cliCiudad}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {ciudadesData.map((item) => {
                        return (
                          <MenuItem key={item.ciuId} value={item.ciuNombre}>
                            {item.ciuNombre}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliDireccion"
                      label="Dirección"
                      value={cliDireccion}
                      name="cliDireccion"
                      error={error.cliDireccion}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.cliTelefono ? "error" : "primary"}
                        >
                          Telefonos:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              cliTelefono: [...item.cliTelefono, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {cliTelefono.map((precio, index) => {
                      return (
                        <TextField
                          key={index}
                          type="number"
                          className={clases.formD}
                          id={index + "teléfono"}
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              cliTelefono: cliTelefono.map(
                                (item, indexItem) => {
                                  if (indexItem === index) {
                                    return e.target.value;
                                  } else {
                                    return item;
                                  }
                                }
                              ),
                            });
                          }}
                          label={`Teléfono #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      cliTelefono: cliTelefono.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                    <TextField
                      type={"date"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      size="small"
                      id="cliTiempoInicioContrato"
                      label="Inicio de Contrato"
                      value={cliTiempoInicioContrato}
                      name="cliTiempoInicioContrato"
                      error={error.cliTiempoInicioContrato}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type={"date"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      size="small"
                      id="cliTiempoFinalContrato"
                      label="Fin de Contrato"
                      value={cliTiempoFinalContrato}
                      name="cliTiempoFinalContrato"
                      error={error.cliTiempoFinalContrato}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="latitud"
                      label="Ubicación GPS"
                      value={
                        cliUbicacion.latitud + ", " + cliUbicacion.longitud
                      }
                      name="latitud"
                      error={error.latitud}
                      // onChange={(e) => {
                      //   setitem({
                      //     ...item,
                      //     cliUbicacion: {
                      //       ...item.cliUbicacion,
                      //       [e.target.name]: e.target.value,
                      //     },
                      //   });
                      // }}
                      InputProps={{
                        endAdornment: (
                          <Box htmlFor="contained-button-documento" fullWidth>
                            <InputAdornment>
                              <Tooltip title="Mostrar Mapa" arrow>
                                <Box>
                                  <IconButton
                                    aria-label=""
                                    onClick={() => setopenMap(true)}
                                  >
                                    <Map color="secondary" />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            </InputAdornment>
                          </Box>
                        ),
                      }}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      id="cliAdministradorContrato"
                      label="Administrador Contrato"
                      value={cliAdministradorContrato}
                      name="cliAdministradorContrato"
                      error={error.cliAdministradorContrato}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={!usuario.rol.includes("ADMIN")}
                      type={"number"}
                      className={clases.formD}
                      size="small"
                      label="Per. Fijo"
                      value={perGuardias}
                      name="perGuardias"
                      error={error.perGuardias}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]:
                            e.target.valueAsNumber < 1 ? "1" : e.target.value,
                        });
                      }}
                    />{" "}
                    <TextField
                      disabled={!usuario.rol.includes("ADMIN")}
                      type={"number"}
                      className={clases.formD}
                      size="small"
                      label="Per. Rotativo"
                      value={perFijo}
                      name="perFijo"
                      error={error.perFijo}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]:
                            e.target.valueAsNumber < 1 ? "1" : e.target.value,
                        });
                      }}
                    />
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.perEmail ? "error" : "primary"}
                        >
                          Correos:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              perEmail: [...item.perEmail, ""],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {perEmail.map((precio, index) => {
                      return (
                        <TextField
                          key={index}
                          className={clases.correo}
                          id=""
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              perEmail: perEmail.map((item, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                          label={`Correo #${index + 1}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      perEmail: perEmail.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                  </>
                ) : null}
                {DatosOperativos ? (
                  <>
                    <Paper sx={{ width: "100%" }}>
                      <ToolBarTable
                        ocultarPaginacion={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Datos Operativos",
                            texto: "Datos Operativos",
                            funcion: () => {
                              setagregandoDatoOperativo(true);
                            },
                            disabled: true,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: false,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />
                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={cliDatosOperativos.length}
                                rowsPerPage={rowsPerPageDatoOperativo}
                                nextIconButtonText="Siguiente página"
                                page={pageDatoOperativo}
                                onPageChange={handleChangePageDatoOperativo}
                                onRowsPerPageChange={
                                  handleChangeRowsPerPageDatoOperativo
                                }
                              />
                            </Box>
                          </caption>

                          <TableHead>
                            <TablaCabecera
                              input={{ id: "" }}
                              columns={[
                                {
                                  id: "Lugar",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Lugar",
                                },

                                {
                                  id: "Puesto",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Puesto",
                                },

                                {
                                  id: "Supervisor",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Supervisor",
                                },

                                {
                                  id: "Guardias",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Guardias",
                                },

                                {
                                  id: "Horas Servicio",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "H.Servicio",
                                },

                                {
                                  id: "Tipo Instalación ",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Tipo Instalación ",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody>
                            {agregandoDatoOperativo ? (
                              <RowAgreagarDatoOperativo
                                setagregar={setagregandoDatoOperativo}
                                setitem={setitem}
                                item={item}
                              />
                            ) : null}
                            {cliDatosOperativos
                              .slice(
                                pageDatoOperativo * rowsPerPageDatoOperativo,
                                pageDatoOperativo * rowsPerPageDatoOperativo +
                                  rowsPerPageDatoOperativo
                              )
                              .map((row, index) => {
                                return (
                                  <RowDatoOperativo
                                    deshabilitarbotones={false}
                                    item={item}
                                    setitem={setitem}
                                    row={row}
                                    index={index}
                                    key={index}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}
                {DatosLogistica ? (
                  <>
                    <FormControl className={clases.formD}>
                      <InputLabel id="demo-mutiple-chip-label">
                        Equipo asignado
                      </InputLabel>
                      <Select
                        error={error.cliEquiposAsignados}
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        name="cliEquiposAsignados"
                        value={cliEquiposAsignados}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                          <div className={clases.chips}>
                            {selected.map((value, index) => {
                              return (
                                <Chip
                                  key={index}
                                  label={value}
                                  style={{ margin: "0rem", padding: "0px" }}
                                />
                              );
                            })}
                          </div>
                        )}
                      >
                        {[
                          "CHALECO",
                          "RADIO",
                          "TOLETES",
                          "CINTO",
                          "PONCHO",
                          "BITACORA",
                          "OTROS",
                        ].map((row, index) => (
                          <MenuItem
                            key={index}
                            value={row}
                            style={getStylesEspecialidades(
                              row,
                              cliEquiposAsignados,
                              theme
                            )}
                          >
                            {row}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Paper className={clases.root}>
                      <ToolBarTable
                        ocultarPaginacion={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Armas Asignadas",
                            texto: "Armas Asignadas",
                            funcion: () => {
                              setagregandoBodega(true);
                            },
                            disabled: false,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: false,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />
                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          id="tabla"
                          size="small"
                        >
                          <caption
                            style={{
                              padding: "0px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <TablePagination
                                labelRowsPerPage="Filas por pagina"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={cliArmasAsignadas.length}
                                rowsPerPage={rowsPerPage}
                                nextIconButtonText="Siguiente página"
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Box>
                          </caption>

                          <TableHead>
                            <TablaCabecera
                              input={{ id: "" }}
                              columns={[
                                {
                                  id: "nombre",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Nombre",
                                },

                                {
                                  id: "serie",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Serie",
                                },

                                {
                                  id: "marca",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "Marca",
                                },

                                {
                                  id: "numpermiso",
                                  align: "center",
                                  minWidth: 50,
                                  tipo: "string",
                                  label: "N. Permiso",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody>
                            {agregandoBodega ? (
                              <RowAgregarArma
                                setagregar={setagregandoBodega}
                                setitem={setitem}
                                item={item}
                              />
                            ) : null}
                            {cliArmasAsignadas.map((row, index) => {
                              return (
                                <RowArma
                                  deshabilitarbotones={false}
                                  item={item}
                                  setitem={setitem}
                                  row={row}
                                  index={index}
                                  key={index}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <TextField
                      multiline
                      rows={2}
                      variant="outlined"
                      className={clases.formD}
                      size="small"
                      id="cliOtrosEquipos"
                      label="OTROS EQUIPOS"
                      value={cliOtrosEquipos}
                      name="cliOtrosEquipos"
                      error={error.cliOtrosEquipos}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
                {Adjuntos ? (
                  <>
                    {documentosArray.map(
                      (documentoItem, index, arraydocumentoItem) => {
                        // extension: "PDF"
                        // fecExpira: "NO"
                        // nombre: "Fotos Instalacion"
                        // obligatorio: "SI"
                        // propiedad: "fotosinstalacion"
                        return (
                          <Box
                            key={index}
                            display="flex"
                            flexWrap="wrap"
                            alignItems="flex-end"
                            borderRadius="0px 50px 50px 0px"
                            p={1}
                            className={clases.formD}
                          >
                            <input
                              className={clases.ocultar}
                              type="file"
                              name="myImage"
                              onChange={(e) => {
                                onChangeDocumento(e, documentoItem);
                              }}
                              ref={objetoReferencias[documentoItem.propiedad]}
                            />
                            <TextField
                              size="small"
                              className={clases.formDDoc}
                              error={documentoItem.error}
                              inputProps={{}}
                              InputLabelProps={{
                                shrink:
                                  documentosObject[documentoItem.propiedad] !==
                                    "" ||
                                  item.documentos[documentoItem.propiedad] !==
                                    ""
                                    ? true
                                    : false,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <Box
                                    htmlFor="contained-button-documento"
                                    fullWidth
                                  >
                                    <InputAdornment>
                                      <Tooltip
                                        title={`Subir Archivo: ${documentoItem.nombre}`}
                                        arrow
                                      >
                                        <Box>
                                          <IconButton
                                            onClick={() =>
                                              focusInputDocumento(documentoItem)
                                            }
                                            aria-label=""
                                            size="small"
                                          >
                                            <CloudUpload
                                              color="secondary"
                                              fontSize="small"
                                            />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>

                                      {item.documentos[
                                        documentoItem.propiedad
                                      ] === "" ? null : (
                                        <Tooltip
                                          title={`Descargar Archivo: ${documentoItem.nombre}`}
                                          arrow
                                        >
                                          <Box>
                                            <IconButton
                                              color="primary"
                                              onClick={async () => {
                                                window.open(
                                                  item.documentos[
                                                    documentoItem.propiedad
                                                  ]
                                                );
                                              }}
                                              aria-label=""
                                            >
                                              <Download />
                                            </IconButton>
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </InputAdornment>
                                  </Box>
                                ),
                              }}
                              fullWidth
                              id={documentoItem.propiedad}
                              label={`${documentoItem.nombre}`}
                              value={
                                item.documentos[documentoItem.propiedad] &&
                                !documentosObject[documentoItem.propiedad].name
                                  ? item.documentos[
                                      documentoItem.propiedad
                                    ].split("/")[
                                      item.documentos[
                                        documentoItem.propiedad
                                      ].split("/").length - 1
                                    ]
                                  : documentosObject[documentoItem.propiedad]
                                      .name
                                  ? documentosObject[documentoItem.propiedad]
                                      .name
                                  : ""
                              }
                              name={documentoItem.propiedad}
                            />

                            {documentoItem.fecExpira === "SI" ? (
                              <TextField
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  min: FechaExacta,
                                }}
                                className={clases.formExp}
                                size="small"
                                id="perFecExpdatospersonales"
                                label="Expira"
                                value={
                                  item.documentos[
                                    documentoItem.propiedad + "expira"
                                  ]
                                }
                                name="perFecExpdatospersonales"
                                error={error.perFecExpdatospersonales}
                                onChange={(e) =>
                                  setitem({
                                    ...item,
                                    documentos: {
                                      ...documentos,
                                      [documentoItem.propiedad + "expira"]: e
                                        .target.value,
                                    },
                                  })
                                }
                              />
                            ) : null}
                          </Box>
                        );
                      }
                    )}
                  </>
                ) : null}
                {Fotografias && (
                  <>
                    <Box
                      display="flex"
                      justifyContent="center"
                      className={clases.formAllW}
                      flexDirection="column"
                      alignItems="center"
                    >
                      <input
                        id="id-input-perLogo"
                        className={clases.ocultar}
                        type="file"
                        name="perLogo"
                        multiple
                        onChange={onChangeArchivoLogo}
                      />

                      {archivoLogo ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="id-input-perLogo">
                            <Avatar
                              htmlFor="id-input-perLogo"
                              alt="Remy Sharp"
                              src={archivoMostradoLogo}
                              className={clases.avatar}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            Logo - {archivoLogo.name}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className={clases.center}
                        >
                          <label htmlFor="id-input-perLogo">
                            <Avatar
                              htmlFor="id-input-perLogo"
                              alt="Logo"
                              src={item.perLogo}
                              className={clases.avatar}
                            />
                          </label>
                          <Typography
                            variant="body1"
                            color="initial"
                            align="center"
                          >
                            No se ha seleccionado ningun archivo (Logo)
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Dropzone
                      textoDropzone="Arrastre aqui una imagen o foto de las INSTALACIONES"
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={perFotografiasInstalaciones}
                    />
                  </>
                )}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
